export default {
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
                .finally(() => {
                    this.$router.push({name: 'logged-out', params: {status: 'success', fromRemoveAuth: true}})
                })
        }
    }
}
