import logoutMixin from '@/common/logout';

export default {
    name: 'logout-confirm',
    mixins: [logoutMixin],
    methods: {
        confirm() {
            this.logout()
        },
        close() {
            this.$emit('close')
        }
    }
}
