import Vue from 'vue'
import VueRouter from 'vue-router'

//added for NavigationDuplicated error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const Home = () => import('@views/public/pages/Home')
const Login = () => import('@views/public/pages/Login')
// const UploadLogin = () => import('@views/public/pages/Login/PopUp/UploadLogin')
const Faq = () => import('@views/public/pages/Faq')
const Support = () => import('@views/public/pages/Support')
const CustomPage = () => import('@views/public/pages/CustomPage')
const RedirectUrl = () => import('@views/public/pages/RedirectUrl')
const CookiePolicy = () => import('@views/public/pages/CookiePolicy')

//not really used
const Contact = () => import('@views/public/Contact')
const Affiliates = () => import('@views/public/Affiliates')

const ExclusivePromotion = () => import('@views/public/pages/ExclusivePromotion')
const LogoutPromotion = () => import('@views/public/pages/LogoutPromotion')
const DeleteAccountPromotion = () => import('@views/public/pages/DeleteAccountPromotion')
const BlockedRegister = () => import('@views/public/pages/BlockedRegister')
const FailedOauth = () => import('@views/public/pages/FailedOauth')

const UserMobileHome = () => import('@views/user/mobile/Home')

const UserActivateProfile = () => import('@views/user/pages/ActivateProfile')
const UserHome = () => import('@views/user/pages/Home')
const UserProfile = () => import('@views/user/pages/Profile')
const UserOtherProfile = () => import('@views/user/pages/OtherProfile')
const UserPhotos = () => import('@views/user/pages/Photos')
const UserMessages = () => import('@views/user/pages/Messages')
const UserMessagesBin = () => import('@views/user/pages/MessagesBin')
const UserMessagesChat = () => import('@views/user/pages/MessagesChat')
const UserVisits = () => import('@views/user/pages/Visits')
const UserFavorites = () => import('@views/user/pages/Favorites')
const UserOtherFavorites = () => import('@views/user/pages/OtherFavorites')
const UserSearchProfiles = () => import('@views/user/pages/SearchProfiles')
const UserSearchResults = () => import('@views/user/pages/SearchResults')
const UserSettings = () => import('@views/user/pages/Settings')
const UserCompletedRegister = () => import('@views/user/pages/CompletedRegister')
const UserBoostedProfile = () => import('@views/user/pages/BoostedProfile')
const UserClaimedDailyFreeIcebreakers = () => import('@views/user/pages/ClaimedDailyFreeIcebreakers')
const UserValidateNewEmail = () => import('@views/user/ValidateNewEmail')
const UserDisableNotifications = () => import('@views/user/pages/DisableNotifications')
const StopNotifications = () => import('@views/public/pages/StopNotifications')
const UserSubscriptionCredits = () => import('@views/user/pages/SubscriptionCredits')
const UserPremium = () => import('@views/user/pages/Premium')
const UserPurchaseConfirmation = () => import('@views/user/PurchaseConfirmation')
const ReceiveSubscription = () => import('@views/user/ReceiveSubscription')

Vue.use(VueRouter)

import store from '@store'
const $t = Vue.i18n.translate

let routes = [
	{
		path: '',
		name: 'home',
		component: Home,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: '/faq',
		name: 'faq',
		component: Faq,
	},
	{
		path: '/contact',
		name: 'contact',
		component: Support, //should've been for contact component
	},
	{
		path: '/support',
		name: 'support',
		component: Support,
	},
	{
		path: '/affiliates',
		name: 'affiliates',
		component: Affiliates,
	},
	{
		path: '/cookie-policy',
		name: 'cookie-policy',
		component: CookiePolicy,
	},
	{
		path: '/dashboard',
		name: 'user-home',
		component: UserHome
	},
	{
		path: '/mobile-dashboard',
		name: 'user-mobile-home',
		component: UserMobileHome,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/activate-profile/:token',
		name: 'user-activate-profile',
		component: UserActivateProfile
	},
	{
		path: '/validate-new-email-address/:token',
		name: 'user-validate-new-email',
		component: UserValidateNewEmail
	},
	{
		path: '/edit-profile',
		name: 'user-profile',
		component: UserProfile,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/profile/:name',
		name: 'user-other-profile',
		component: UserOtherProfile,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/my-picture',
		name: 'user-photos',
		component: UserPhotos,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/messages/inbox',
		name: 'user-messages',
		component: UserMessages,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/messages/bin',
		name: 'user-messages-bin',
		component: UserMessagesBin,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/messages/send/:name',
		name: 'user-messages-chat',
		component: UserMessagesChat,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/visitor',
		name: 'user-visits',
		component: UserVisits,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/favorite/my',
		name: 'user-favorites',
		component: UserFavorites,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/favorite/others',
		name: 'user-other-favorites',
		component: UserOtherFavorites,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/search',
		name: 'user-search-profiles',
		component: UserSearchProfiles,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/search-result',
		name: 'user-search-results',
		component: UserSearchResults,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/settings',
		name: 'user-settings',
		component: UserSettings,
		meta: {
			requiresAuth: true
		}
	},
    {
        path: '/complete-register/:token',
        name: 'complete-register',
        component: UserCompletedRegister,
        meta: {
            // requiresAuth: true
        }
    },
    {
        path: '/boost-profile/:token',
        name: 'boost-profile',
        component: UserBoostedProfile,
        meta: {
            // requiresAuth: true
        }
    },
    {
        path: '/claim-daily-icebreakers/:token',
        name: 'claim-daily-icebreakers',
        component: UserClaimedDailyFreeIcebreakers,
        meta: {
            // requiresAuth: true
        }
    },
	{
		path: '/delete-account',
		redirect: {
			name: 'user-settings',
			params: {
				open: 'delete-account'
			}
		},
	},
	{
		path: '/disable-notifications',
		name: 'user-disable-notifications',
		component: UserDisableNotifications,
		meta: {
			requiresAuth: true
		}
	},
    {
        path: '/stop-notifications',
        name: 'user-stop-notifications',
        component: StopNotifications
    },
	{
		path: '/credits',
		name: 'user-credits',
		component: UserSubscriptionCredits,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/subscription/exit',
		name: 'user-receive-subscription',
		component: ReceiveSubscription,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/premium',
		name: 'user-premium',
		component: UserPremium,
		meta: {
			requiresAuth: true,
            subscriptionAllowed: true
		}
	},
	{
		path: '/purchase-confirmation',
		name: 'user-purchase-confirmation',
		component: UserPurchaseConfirmation,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/exclusive-promotion',
		name: 'exclusive-promotion',
		component: ExclusivePromotion
	},
	{
		path: '/logged-out',
		name: 'logged-out',
		component: LogoutPromotion,
		meta: {
			requiresGuest: true
		}
	},
	{
		path: '/account-deleted',
		name: 'account-deleted',
		component: DeleteAccountPromotion
	},
	{
		path: '/oops',
		name: 'blocked-register',
		component: BlockedRegister
	},
	{
		path: '/oopsoauth',
		name: 'failed-oauth',
		component: FailedOauth
	},
	{
		path: '/redirect-url',
		name: 'redirect-url',
		component: RedirectUrl
	},
	{
		path: '/terms-and-conditions',
		name: 'terms',
		component: CustomPage,
		props: (route) => ({ page_name: route.path.substring(1) })
	},
	{
		path: '*',
		name: 'custom',
		component: CustomPage,
		props: (route) => ({ page_name: route.path.substring(1) })
  },
]

import {checkNull} from '@/helpers'
import theme_settings from '@theme-views'
let metas = JSON.parse(window.APP_CONFIG.METAS)
let meta_default = window.APP_CONFIG.META_DEFAULT
for(let key in routes) {
	let o_metas = {}
	let s_name = routes[key]['name']
	if(s_name == 'support') s_name = 'contact'

	if(routes[key]['meta']) o_metas = routes[key]['meta']

	if(checkNull(metas[s_name])) {
		o_metas['title'] = metas[s_name]['meta_title']
		o_metas['description'] = metas[s_name]['meta_description']
		o_metas['keywords'] = metas[s_name]['meta_keywords']
	} else if(checkNull(metas[$t(s_name)])) {
		o_metas['title'] = metas[$t(s_name)]['meta_title']
		o_metas['description'] = metas[$t(s_name)]['meta_description']
		o_metas['keywords'] = metas[$t(s_name)]['meta_keywords']
	} else {
		o_metas['title'] = meta_default['title']
		o_metas['description'] = meta_default['description']
		o_metas['keywords'] = meta_default['keywords']
	}

	routes[key]['meta'] = o_metas

	let theme_route = ( checkNull(theme_settings.routes) ) ? theme_settings.routes.find( (val, key) => {
		return s_name ? val.name == s_name : null
	}) : null
	if(checkNull(theme_route)) {
		routes[key]['component'] = theme_route['component']
		routes[key]['props'] = theme_route['props']
		if(theme_route['path']) routes[key]['path'] = theme_route['path']
		if(theme_route['redirect']) routes[key]['redirect'] = theme_route['redirect']
    if(theme_route['meta']) routes[key]['meta'] = Object.assign(routes[key]['meta'], theme_route['meta'])
	}
}

let new_theme_routes =  ( checkNull(theme_settings.routes) ) ? theme_settings.routes.filter((val) => {
	if(checkNull(val.new_route)) {
		delete(val.new_route)
		return true
	}
}) : null

if(checkNull(new_theme_routes)) {
	new_theme_routes.map(val => {
		let path_name = val.path
		let skey = null
		let same_path_route = routes.find((val,key) => {
			if(val.path == path_name) {
				skey = key
				return true
			}
		})
		if(checkNull(same_path_route)) {
			if(checkNull(same_path_route.meta)) Object.assign(val.meta, same_path_route.meta)
			routes.splice(skey, 1)
		}
		routes.push(val)
	})
}

export const defaultRoutes = routes;

export default new VueRouter({
	hashbang: false,
	history: true,
	mode: 'history',
	linkActiveClass: 'active',
	scrollBehavior (to, from, savedPosition) {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
		// return { x: 0, y: 0 }
	}
})
